import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout/Layout'
import Main from 'components/layout/Main'
import Header from 'components/header/Header'
import aboutJpg from 'assets/about.jpg'

const StyledMain = styled(Main)`
  display: flex;

  & > * {
    flex: 1 1 auto;
  }
`

const ImgWrapper = styled.div`
  margin-right: 2em;

  @media (max-width: 768px) {
    display: none;
  }
`

const TextWrapper = styled.div`
  p:first-of-type {
    margin-top: 0;
  }
`

const Img = styled.img`
  width: 100%;
`

const FlowingImg = styled.img`
  display: none;
  float: right;
  margin: 0 0 0 1em;
  width: 200px;

  @media (max-width: 768px) {
    display: block;
  }
`

const AboutPage: React.FunctionComponent = () => (
  <Layout>
    <Header text="О себе" />
    <StyledMain>
      <ImgWrapper>
        <Img src={aboutJpg} alt="Моя фотография" />
      </ImgWrapper>
      <TextWrapper>
        <FlowingImg src={aboutJpg} alt="Моя фотография" />
        <p>Давайте знакомиться!</p>
        <p>
          Меня зовут Яна, я родом из города Гродно. Уже 10 лет занимаюсь профессиональной
          видеосъёмкой. Всё началось с фотографии, потом меня захлестнул процесс создания видео.
        </p>
        <p>
          В 2013 году закончила Минскую академию искусств по специальности телеоператор. В 2015 году
          переехала жить в Краков. Этот город вдохновил меня на новые проекты. Став мамой, я
          прониклась семейной съёмкой.
        </p>
        <p>
          <a href="/portfolio">Имею опыт</a> съёмок:
        </p>
        <ul>
          <li>Концертов</li>
          <li>Танцевальные проектов</li>
          <li>Рекламных роликов</li>
          <li>Фуд-блогов</li>
          <li>Семейных видео</li>
          <li>Выписок из роддома, кристин, венчаний</li>
          <li>Дней рождений и праздников</li>
          <li>Корпоративов</li>
          <li>Love story</li>
        </ul>
        <p>Также могу смонтировать фильм из ваших видео.</p>
        <p>Моё оборудование:</p>
        <ul>
          <li>Камера Sony a7R2</li>
          <li>Объектив Tamron 28-75 mm F2.8</li>
          <li>Стабилизатор DJI Ronin SC</li>
          <li>Микрофон Zoom H1n</li>
        </ul>
        <p>Буду очень рада запечатлеть ваши приятные моменты!</p>
      </TextWrapper>
    </StyledMain>
  </Layout>
)

export default AboutPage
